/* TODO how change static images */
import 'lazysizes/plugins/bgset/ls.bgset.js';
import 'lazysizes/plugins/parent-fit/ls.parent-fit.js';
// slider
import Swiper, {Navigation, Pagination, Thumbs, FreeMode, Grid} from "swiper";
// custom select
import 'jquery-form-styler';
// functions
import {headerScrollChange} from "./_func/_jq/headerScrollChange";
import {getAppHeight} from "./_func/_jq/getAppHeight";
import {getScrollWidth} from "./_func/_jq/getScrollWidth";
import {inputCount} from "./_func/_jq/inputCount";
import {simpleModal, videoModal, modxModal} from "./_func/_jq/modalMagnific";
import {scrollTo} from "./_func/_jq/scrollTo";
import {menuBtn} from "./_func/_jq/menuBtn";
/* ------------------- */


document.addEventListener('DOMContentLoaded', () =>  {
    // app height fix
    getAppHeight();
    getScrollWidth();
    // scroll change
    headerScrollChange();
    // mobile menu
    menuBtn();
    // lang menu
    $('.js-lang-menu').click(function (){
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).parent().find('.lang-list').slideUp(200);
        }
        else{
            $(this).addClass('active');
            $(this).parent().find('.lang-list').slideDown(200);
        }
    });
    $(document).click(function (e) {
        if(!e.target.classList.contains('js-lang-menu') && $('.js-lang-menu').hasClass('active')){
            $('.js-lang-menu').removeClass('active');
            $('.js-lang-menu').parent().find('.lang-list').slideUp(200);
        }
    });
    // custom selects
    setTimeout(function() {
        $('.select-style').styler();
    }, 100);
    // count function
    inputCount();

    /* main resize change */
    function changeHeaderResize(){
        if($(window).width() <= 960){
            $('.header__content').addClass('mobile-update');
        }
        else{
            $('.header__content').removeClass('mobile-update');
        }
        if($('.header__content').hasClass('mobile-update')){
            $('.header__content .search-block').prependTo($('.header__content .main-menu'));
            $('.header__content .links-block').appendTo($('.header__content .main-menu'));
            if($(window).width() <= 413){
                $('.header__content .main-menu .main-menu__list').after($('.header__content .phone-link'));
            }
            else{
                $('.header__content .lang-block').after($('.header__content .main-menu .phone-link'));
            }
        }
        else{
            $('.header__content .logo-link').after($('.header__content .main-menu .search-block'));
            $('.header__content .search-block').after($('.header__content .main-menu .links-block'));
        }
    }
    changeHeaderResize();
    $(window).resize(function(){
        changeHeaderResize();
    })

    /* main page */
    // products slider
    document.querySelectorAll('.products-slider').forEach(productsSlider => {
        var productsSlider = new Swiper(productsSlider, {
            modules: [Navigation],
            lazy: true,
            speed: 800,
            loop: true,
            slidesPerView: 1,
            spaceBetween: 10,
            simulateTouch: false,
            navigation: {
                prevEl: productsSlider.parentElement.parentElement.querySelector('.slider-navigation .nav-btn.prev-btn'),
                nextEl: productsSlider.parentElement.parentElement.querySelector('.slider-navigation .nav-btn.next-btn')
            },
            breakpoints: {
                960: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 3,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                341: {
                    slidesPerView: 2
                }
            }
        });
    });
    // product slider
    document.querySelectorAll('.product-item .product-slider').forEach(productSlider => {
        var productSlider = new Swiper(productSlider, {
            modules: [Navigation, Pagination],
            lazy: true,
            speed: 800,
            loop: true,
            slidesPerView: 1,
            spaceBetween: 10,
            pagination: {
                clickable: true,
                el: productSlider.parentElement.parentElement.querySelector('.slider-pagination')
            }
        });
    });
    // answers slider
    var answersSlider = new Swiper('#answers-slider', {
        modules: [Navigation],
        lazy: true,
        speed: 800,
        simulateTouch: false,
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            prevEl: '.main-answers .slider-navigation .nav-btn.prev-btn',
            nextEl: '.main-answers .slider-navigation .nav-btn.next-btn'
        },
        breakpoints: {
            1200: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 2,
            }
        }
    });
    // answers video
    document.querySelectorAll('.answer-item').forEach(videoAnswer => {
        if(videoAnswer.querySelector('.item-video') !== null){
            videoAnswer.addEventListener('mouseover', function (){
                var videoItem = this.querySelector('.item-video');
                videoItem.play();
            });
            videoAnswer.addEventListener('touchstart', function (){
                var videoItem = this.querySelector('.item-video');
                videoItem.play();
            });
            videoAnswer.addEventListener('mouseout', function (){
                var videoItem = this.querySelector('.item-video');
                videoItem.pause();
                // videoItem.currentTime = 0;
            });
            videoAnswer.addEventListener('touchend', function (){
                var videoItem = this.querySelector('.item-video');
                videoItem.pause();
                // videoItem.currentTime = 0;
            });
        }
    });
    // calc function
    function profit_calc(id){
        const form = $('#'+ id);
        var dollar_balance = parseFloat(form.find('input[name="dollar_balance"]').val().replace(',','.')),
            btc_balance = parseFloat(form.find('input[name="btc_balance"]').val().replace(',','.')),
            electro_price = parseFloat(form.find('input[name="electro_price"]').val().replace(',','.')),
            product_electro = parseFloat(form.find('select option:checked').attr('data-energo').replace(',','.')),
            product_profit = parseFloat(form.find('select option:checked').attr('data-profit').replace(',','.')),
            count = parseInt(form.find('input[name="count"]').val());
        var result = ((btc_balance*count*product_profit)-(count*product_electro/1000*24*1/dollar_balance*electro_price))*30.5;
        form.find('.result_value').text('$ ' + result.toFixed(2));
    }
    if($('#main-calc').length > 0){
        profit_calc('main-calc');
        $('#main-calc').change(function (){
            profit_calc('main-calc');
        });
    }
    // answers info
    $('.js-info-link').click(function (){
        if($(this).hasClass('active')){
            $(this).removeClass('active').parent().find('.item-descr').slideUp(300);
        }
        else{
            $(this).addClass('active').parent().find('.item-descr').slideDown(300);
        }
    });
    /* product page */
    if($('.product-main').length > 0){
        var productThumbs = new Swiper("#mproduct-thumb", {
            modules: [Thumbs, FreeMode],
            speed: 800,
            spaceBetween: 15,
            slidesPerView: 5,
            direction: 'vertical',
            freeMode: true,
            watchSlidesProgress: true,
        });
        var productMain = new Swiper("#mproduct-slider", {
            modules: [Thumbs, FreeMode],
            speed: 800,
            spaceBetween: 10,
            thumbs: {
                swiper: productThumbs,
            },
        });
    }
    /* shop page */
    // scroll link
    scrollTo('.js-scroll-link', -50);
    // calc range
    if(document.getElementById('range')){
        var btc_range = document.getElementById('range'),
            btc_from = parseInt(btc_range.getAttribute('data-from')),
            btc_to = parseInt(btc_range.getAttribute('data-to'));
        noUiSlider.create(btc_range, {
            start: btc_from,
            connect: 'lower',
            step: 500,
            tooltips: true,
            format: {
                from: function (value) {
                    return parseInt(value);
                },
                to: function (value) {
                    return parseInt(value);
                }
            },
            range: {
                'min': btc_from,
                'max': btc_to
            }
        });
        btc_range.noUiSlider.on('update', function (values, handle){
            $('#shop-calc').trigger('change');
        });
    }
    // shop calc
    function shop_calc(){
        var power = parseFloat($('#shop-calc').attr('data-power')),
            days = parseInt($('#shop-calc').attr('data-days')),
            electro = parseInt($('#shop-calc').attr('data-electro')),
            count = parseInt($('#shop-calc').find('input[name="packet"]:checked').val()),
            dollar = parseInt($('#shop-calc').attr('data-dollar')),
            btc = btc_range.noUiSlider.get();
        var result = (btc * (count * power )) * dollar * days - (count * electro);
        $('#shop-calc').find('.result-block').text(parseInt(result).toLocaleString('ru'));
    }
    if($('#shop-calc').length > 0){
        shop_calc();
        $('#shop-calc').on('change', function (){
            shop_calc();
        });
    }
    // hosting calc
    if($('#hosting-calc').length > 0){
        profit_calc('hosting-calc');
        $('#hosting-calc').change(function (){
            profit_calc('hosting-calc');
        });
    }

    /* modals */
    $('.js-simple-modal').click(function (){
        if($(this).attr('data-product')){
            $('#order_modal input[name="product"]').val($(this).attr('data-product'));
        }
        else{
            $('#order_modal input[name="product"]').val('');
        }
    })
    videoModal();
    simpleModal();
    modxModal();
})